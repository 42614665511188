/* Language direction support. */
html {
    --text-dir: ltr;
}

html[lang='ar'] {
    --text-dir: rtl;
}

html,
body {
    direction: var(--text-dir);
    text-size-adjust: 100%;
    overflow-x: hidden; /* prevents horiz scroll (e.g. with Nivo tooltips) */
}

[dir='rtl'] {
    direction: rtl;
}

/* Mapbox "Toggle attribution" button. */
.mapboxgl-ctrl-attrib-button {
    display: none;
}

/* Move Google Maps attribution left on larger displays.
It moves back right when the attribution starts to be hidden. */
@media (min-width: 500px) {
    .gm-style > div:last-child > div {
        left: 75px;
    }
}

.mapboxgl-control-container .mapboxgl-ctrl-bottom-right {
    display: flex;
    flex-direction: row-reverse;
    background-color: #ffffff80;
    padding: 5px;
    align-items: flex-end;
}
.mapboxgl-control-container .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
    margin: 0 5px 0 0;
    background-color: unset;
}
.mapboxgl-control-overlay.overlay-bottom-right {
    background-color: unset;
}
